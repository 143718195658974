<template>
  <div class="reply-fame">
    <div class="user">
      <img v-if=" replyInfo.writerInfo.writerName === '관리자'"
           src="@/assets/images/IA/master_icon.png" class="user-icon" alt="">
      <img v-else
           src="@/assets/images/IA/user_icon.png" class="user-icon" alt="">
      <p v-if=" replyInfo.writerInfo.writerName === '관리자'" style="color: #2E8AF4">{{ replyInfo.writerInfo.writerName }}</p>
      <p v-else>{{ replyInfo.writerInfo.writerName }}</p>
      <p class="date-Time">{{ replyInfo.regDateTime }}</p>
      <button class="delete-btn" @click="isReplyRemove = true">
        <img src="@/assets/images/IA/icon_delete_red.png" class="delete-icon" alt="">
        삭제
      </button>
    </div>
    <div class="reply-text-box">
      <p v-if="replyInfo.isDeleted" style="color:#D23442" >관리자 권한에 의해 삭제처리된 댓글입니다.</p>
      <p v-else>{{ replyInfo.contents }}</p>
    </div>
    <div class="bottom-line"></div>
    <div class="modal-cover" v-show="isReplyRemove"></div>
    <ReplyRemoveDialog @closeModal="closeModal" @deleteReply="deleteReply" v-if="isReplyRemove === true"/>
  </div>
</template>

<script>
import {firebase} from "@/firebase/firebaseConfig";
import ReplyRemoveDialog from "@/views/qna/ReplyRemoveDialog";

export default {
  name: 'QnaManagerReply',
  components: {ReplyRemoveDialog},
  props: {
    replyInfo: Object,
  },
  data() {
    return {
      isReplyRemove: false,
      fbCollection: 'qna',
      userUid: this.$store.state.user.uid,
      postId: this.$route.params.id,
    }
  },
  methods: {
    closeModal() {
      const self = this;
      self.isReplyRemove = false;
    },
    deleteReply() {
      const self = this;
      const db = firebase.firestore();
      const _data = {
        isDeleted : true,
      }
      db.collection(self.fbCollection)
          .doc(self.postId)
          .collection('reply')
          .doc(self.replyInfo.docId)
          .set(_data, {merge : true})
          .then(async () => {
            self.$emit('deleteReply');
          })
    },
  }
}
</script>

<style scoped>

.reply-fame {
  width: 100%;
  padding: 20px 20px 0 20px;
}
.reply-fame .bottom-line {
  margin-top: 20px;
  height: 1px;
  background: #E3E3E3;
}

.reply-fame .user {
  display: flex;
  align-items: center;
}

.reply-fame .user .user-icon {
  height: 20px;
  width: 20px;
  margin-left: 5px;
}

.reply-fame .user p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-left: 5px;
}

.reply-fame .user .date-Time {
  color: #8D8D8D;
}

.reply-fame .user .delete-icon {
  height: 18px;
  width: 18px;
  margin-right: 4px;
}

.reply-fame .user .delete-btn {
  color: #D23442;
  border: 0;
  background: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 25px;
}

.reply-fame .reply-text-box {
  margin-top: 10px;
  word-break: break-all;
}

.reply-fame .reply-text-box p {
  max-width: 1140px;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
}

</style>