<template>
  <div>
    <div class="qna-detail">
      <h4 class="title">문의사항 상세</h4>
      <div class="detail-container">
        <div class="bs-flex">
          <div style="margin-left: auto">
            <button class="table-btn" @click="prevBoard">
              <img src="@/assets/images/IA/icon_prev_post.png" alt="list">
              이전글
            </button>
            <button class="table-btn" @click="nextBoard">
              <img src="@/assets/images/IA/icon_next_post.png" alt="list">
              다음글
            </button>
            <button class="table-btn" @click="refList">
              <img src="@/assets/images/IA/icon_list_view.png" alt="list">
              목록
            </button>
          </div>
        </div>
        <div class="detail-sub-container">
          <h5 v-html="qnaInfo.title" class="subTitle"></h5>
          <div class="bs-flex">
            <p v-html="qnaInfo.regDateTime" class="subDate"></p>
            <p class="subViewTitle">등록인 </p>
            <p class="subView" v-if="qnaInfo.writerInfo.writerName != null">{{ qnaInfo.writerInfo.writerName }}</p>
            <p class="subViewTitle">조회수 </p>
            <p v-html="qnaInfo.viewCount + 1" class="subView"></p>
          </div>
          <hr class="line">
          <mdb-input type="textarea" id="editor" class="textarea-desc" outline v-model="qnaInfo.desc" readonly :rows="10"/>
        </div>
        <button class="remove-btn custom-btn-box" @click="isRemoveModal = true">
          <img src="@/assets/images/IA/icon_delete.png" alt="">
          삭제
        </button>
        <div class="reply-add-container">
          <h5>댓글달기</h5>
          <div class="reply-user">
            <img src="@/assets/images/IA/master_icon.png" alt="">
            <p style="color: #2E8AF4">관리자</p>
          </div>
          <div class="reply-box">
            <input type="text" v-model="contents" class="custom-input-box"/>
            <button class="reply-btn" @click="addReply">
              <img src="@/assets/images/IA/icon_re.png" alt="">
              등록
            </button>
          </div>
        </div>
        <div class="reply-container">
          <QnaManagerReply :replyInfo="replyList[i]" v-for="(a,i) in replyList" :key="i" @deleteReply="deleteReply"/>
        </div>
      </div>
      <div class="modal-cover" v-show="isRemoveModal"></div>
      <QnaRemoveDialog @closeModal="closeModal" @removeModal="removeModal" v-if="isRemoveModal === true"/>
    </div>
  </div>
</template>

<script>
import QnaRemoveDialog from '@/views/master/qnaManage/QnaRemoveDialog.vue';
import {firebase} from "@/firebase/firebaseConfig";
import {getDate, getDateTime} from "@/lib/prettyDate";
import QnaManagerReply from "@/views/master/qnaManage/QnaMangerReply";
import {mdbInput} from "mdbvue";

export default {
  name: "QnaDetailPage",
  components: {QnaManagerReply, QnaRemoveDialog, mdbInput},
  data() {
    return {
      contents: '',
      isRemoveModal: false,
      isMine: true,
      replies: [],
      fbCollection: 'qna',
      userUid: this.$store.state.user.uid,
      postId: this.$route.params.id,
      qnaList: [],
      qnaInfo: {
        orderNo: 0,
        title: '',
        desc: '',
        regDate: '',
        viewCount: 0,
        writerInfo: {
          writerName: '',
          writerUid: '',
          writerEmail: '',
        }
      },
      currentIndex: 0,
      maxIndex: 100,
      replyList: [],
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const self = this;
      await self.getCurrentData();
      await self.getCurrentReply();
      await self.getDataList();
    },
    async getCurrentData() {
      const self = this;
      const db = firebase.firestore();
      await db.collection(self.fbCollection)
          .doc(self.postId)
          .get()
          .then(async (snapshot) => {
            if (!snapshot.exists) {
              alert('존재하지 않는 게시글 입니다.');
              await self.$router.replace('/master/qnaManage');
              return;
            }
            const _data = snapshot.data();
            const date = new Date(_data.regDate.seconds * 1000);
            _data.regDate = getDate(date);
            _data["regDateTime"] = getDateTime(date);
            self.qnaInfo = _data;
          })
    },
    async getCurrentReply() {
      const self = this;
      self.replyList.splice(0);
      const db = firebase.firestore();
      await db.collection(self.fbCollection)
          .doc(self.postId)
          .collection('reply')
          .orderBy('regDate', 'desc')
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size === 0) {
              return;
            }
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              _data['docId'] = doc.id;
              const date = new Date(_data.regDate.seconds * 1000);
              _data.regDate = getDate(date);
              _data["regDateTime"] = getDateTime(date);
              self.replyList.push(_data);
            });
          });
      // console.log(self.qnaList)
      self.maxIndex = self.qnaList.length;
      self.currentIndex = self.qnaList.findIndex((element) => element.docId === self.postId);
    },
    async getDataList() {
      const self = this;
      self.qnaList.splice(0);
      const db = firebase.firestore();
      await db.collection(self.fbCollection)
          .orderBy('orderNo', 'asc')
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size === 0) {
              return;
            }
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              _data['docId'] = doc.id;
              self.qnaList.push(_data);
            });
          });
      // console.log(self.qnaList)
      self.maxIndex = self.qnaList.length;
      self.currentIndex = self.qnaList.findIndex((element) => element.docId === self.postId);
    },
    async prevBoard() {
      const self = this;
      if (self.currentIndex === 0) {
        alert('마지막 글입니다.');
        return;
      }
      const value = self.qnaList[self.currentIndex - 1];
      await self.$router.replace({name: 'qnaManageDetail', params: {id: value.docId}})
      self.$router.go();
    },
    async nextBoard() {
      const self = this;
      if (self.maxIndex === self.currentIndex + 1) {
        alert('마지막 글입니다.');
        return;
      }
      const value = self.qnaList[self.currentIndex + 1];
      await self.$router.replace({name: 'qnaManageDetail', params: {id: value.docId}})
      self.$router.go();
    },
    closeModal() {
      const self = this;
      self.isRemoveModal = false;
    },
    async removeModal() {
      const self = this;
      const db = firebase.firestore();
      await db.collection(self.fbCollection)
          .doc(self.postId)
          .delete()
          .then(async () => {
            await self.refList();
          })
    },
    async refList() {
      const self = this;
      await self.$router.replace('/master/qnaManage');
    },
    addReply() {
      const self = this;

      if (self.contents.trim().length === 0) {
        alert('작성된 댓글이 없습니다.');
        return
      }

      const db = firebase.firestore();
      const _data = {
        contents: self.contents,
        isDeleted: false,
        regDate: firebase.firestore.Timestamp.fromDate(new Date()),
        writerInfo: {
          writerName: '관리자',
          writerUid: '',
          writerEmail: '',
        }
      }
      const docRef = db.collection(self.fbCollection)
          .doc(self.postId)
          .collection('reply')
          .doc();
      docRef.set(_data)
          .then(() => {
            self.contents = '';
            const dd = {..._data}
            dd.docId = docRef.id;
            dd.regDateTime = '방금전'
            self.replyList.unshift(dd)
          });
    },
    deleteReply() {
      const self = this;
      self.getCurrentReply();
    },

  }
}
</script>

<style scoped>

.qna-detail {
  max-width: 1140px;
  min-height: 565px;
  width: 100%;
  padding: 98px 30px 80px 30px;
  margin: 0 auto;
}

.title {
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-bottom: 40px;
}

.qna-detail .bs-flex {
  display: flex;
}

.qna-detail .bs-flex img {
  height: 18px;
  width: 18px;
}

.qna-detail .table-btn {
  background: #EDEDF1;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #000000;
  border: 0;
  margin-left: 10px;
  padding: 2px 10px;
}

.qna-detail .table-btn img {
  width: 18px;
  height: 18px;
  transform: translateY(-2px);
}
.qna-detail .detail-sub-container {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 20px;
}

.qna-detail .subTitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-bottom: 5px;
  margin-top: 23px;
  margin-left: 32px;
}

.qna-detail .subDate {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
  margin-left: 32px;
  margin-bottom: 23px;

}

.qna-detail .subViewTitle {
  margin: 0 8px 0 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
}

.qna-detail .subView {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
}

.qna-detail .line {
  width: 100%;
  margin: 0;
}

.qna-detail .remove-btn img {
  height: 18px;
  width: 18px;
  margin-right: 4px;
}

.qna-detail .remove-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  background: #D23442;
  margin-left: auto;
  margin-bottom: 30px;
}

.qna-detail .reply-add-container {
  background: #FAFAFA;
  height: 148px;
  padding: 20px;
  border: #E1E3E6 1px solid;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 #E7E7ED;
  margin-bottom: 20px;
}

.reply-add-container h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #8D8D8D;
  margin: 0 0 10px 5px;

}

.reply-add-container .reply-user {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
}

.reply-add-container .reply-user img {
  height: 18px;
  width: 18px;
  margin: 0 5px;
}

.reply-add-container .reply-box {
  display: flex;
}

.reply-add-container .reply-box input {
  outline: 0;
}

.reply-add-container .custom-input-box {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.reply-add-container .reply-btn {
  color: #ffffff;
  background: #20173C;
  border-radius: 4px;
  border: 0;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  height: 40px;
}

.reply-add-container .reply-btn img {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

/deep/ .textarea-desc{
  margin-top: 0 !important;
  margin-bottom: 0 !important;

}

/deep/ .textarea-desc textarea {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #272833;
  border: 0 !important;
  resize: none;
  padding: 23px 32px !important;
  margin: 0;
  overflow: overlay;
  height: 552px;
}

</style>