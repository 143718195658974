<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        사용자의 댓글을 삭제하시겠습니까?
      </h5>
    </div>
    <div class="modal-center">
      <p>삭제된 댓글은 복구가 불가능합니다.<br/>
        다시한번 확인 후 삭제 버튼을 눌러주세요.<br/>
        삭제 처리된 댓글은 사용자 환경에서 경고 문구로 대체됩니다.<br/>
        <strong>(경고문구 : ‘관리자 권한에 의해 삭제처리된 댓글입니다.’)</strong></p>
    </div>
    <div class="modal-footer">
      <button class="custom-btn-box btn-close" @click="closeBtn">취소</button>
      <button class="custom-btn-box btn-confirm" @click="deleteReply">삭제</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReplyRemoveDialog',
  data() {
    return {}
  },
  methods: {
    closeBtn() {
      const self = this;
      self.$emit('closeModal');
    },
    deleteReply(){
      const self = this;
      self.$emit('deleteReply');
    }
  },
}
</script>

<style scoped>

.modal-container {
  position: fixed;
  width: 580px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background: #FFFFFF;
  border-radius: 8px;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
}

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 30px;
  background: #F8F8FA;
}

.modal-center p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
}

.modal-center p strong {
  color: red;
}

.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
}

.modal-footer button {
  margin: 0;
}

.modal-footer button:first-child {
  margin-right: 12px;
}

.btn-close {
  width: 84px;
  height: 40px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  font-weight: 400;
  border-radius: 4px;
  background: #ffffff !important;
  font-size: 16px;
  line-height: 36px;
  text-transform: uppercase;
  color: rgba(24, 24, 25, 0.9);
}

.btn-confirm {
  width: 84px;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  text-transform: uppercase;
  color: white;
  background: #D23442 !important;
}


</style>
